export default class NavStickyAnchors {
    constructor(navSticky) {
        const stickyAnchors = navSticky.querySelector('.sticky-anchors')
        const stickyCta = navSticky.querySelector('.sticky-cta')

        navSticky && (this.navSticky = navSticky)
        stickyAnchors && (this.stickyAnchors = stickyAnchors)
        stickyCta && (this.stickyCta = stickyCta)
    }

    start() {
        if (this.stickyAnchors) {
            this.stickyAnchorsChlidren = this.stickyAnchors.querySelectorAll('.swiper-slide')

            new Swiper('.sticky-anchors', {
                slidesPerView: 'auto',
                freeMode: true,
                spaceBetween: 20,
                breakpoints: {
                    768: {
                        spaceBetween: 60
                    }
                }
            })

            window.addEventListener('resize', () => {
                this.calcStickyAnchorsSlideWidth()
            })
            window.addEventListener('load', () => {
                this.calcStickyAnchorsSlideWidth()
            })
            window.addEventListener('scroll', () => {
                this.handleStickyAnchorsOnScroll()
            })
        }
    }

    calcStickyAnchorsSlideWidth() {
        const navStickyAnchorsWidth = this.navSticky.clientWidth - this.stickyCta?.clientWidth
        const lastAnchorChild = this.stickyAnchorsChlidren[this.stickyAnchorsChlidren.length - 1]
        const lastAnchorBoundaryRight = lastAnchorChild.getBoundingClientRect().right
        const navStickyCtaBoundaryLeft = this.stickyCta?.getBoundingClientRect().left ?? 0

        this.stickyAnchors.style.width = `${navStickyAnchorsWidth}px`

        if (lastAnchorBoundaryRight > navStickyCtaBoundaryLeft) {
            this.stickyAnchors.classList.add('activate-slide')
        } else {
            this.stickyAnchors.classList.remove('activate-slide')
        }
    }

    handleStickyAnchorsOnScroll() {
        this.navStickyPlaceholder = document.querySelector('.nav-sticky-placeholder')
        this.stickyAnchorsChlidren.forEach(element => {
            const relatedBlockPlaceholder = document.getElementById(element.dataset.anchor)
            const relatedBlock = relatedBlockPlaceholder.nextElementSibling

            if (relatedBlockPlaceholder && relatedBlock) {
                const relatedBlockPlaceholderTop = relatedBlockPlaceholder.getBoundingClientRect().top
                const relatedBlockBottom = relatedBlock.getBoundingClientRect().bottom

                if (relatedBlockPlaceholderTop <= 1 && relatedBlockBottom > 1) {
                    element.classList.add('active')
                } else {
                    element.classList.remove('active')
                }
            }
        })

        if (this.navStickyPlaceholder) {
            const navStickyOffsetTop = this.navStickyPlaceholder.getBoundingClientRect().top

            if (navStickyOffsetTop < 0) {
                this.navSticky.classList.add('sticky')
            } else {
                this.navSticky.classList.remove('sticky')
            }
        }
    }
}
