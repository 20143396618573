export default class RelationClient {
    constructor(element) {
        this.element = element
        this.strongPoints = element.querySelector('.strong-points')
        this.strongPointOne = this.strongPoints.querySelector('.num-1')
        this.strongPointThree = this.strongPoints.querySelector('.num-3')
        this.breakpoint = window.matchMedia('(min-width:1280px)')
    }

    start() {
        window.addEventListener('load', this.handleHeightStrongPoints.bind(this))
        window.addEventListener('resize', this.handleHeightStrongPoints.bind(this))
    }

    handleHeightStrongPoints() {
        const strongPointOneHeight = this.strongPointOne?.clientHeight + 24 ?? 0
        const strongPointThreeHeight = this.strongPointThree?.clientHeight + 24 ?? 0
        const strongPointsPadding = 112

        if (this.breakpoint.matches) {
            this.strongPoints.style.minHeight = `${
                strongPointOneHeight + strongPointThreeHeight + strongPointsPadding
            }px`
        } else {
            this.strongPoints.style.minHeight = '550px'
        }
    }
}
