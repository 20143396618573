import customSelect from 'custom-select'

export default class Dropdown {
    constructor(element) {
        this.element = element
        this.customSelect = ''
        this.customSelectOpener = ''
        this.icon = ''
        this.customSelectLabel = ''
    }

    start() {
        this.customSelect = customSelect(this.element)[0]
        this.appendIcon()
        this.customSelect.container.addEventListener('custom-select:open', () => {
            if (this.customSelect.value.trim().length === 0) {
                this.icon.classList.add('open')
            }
        })
        this.customSelect.container.addEventListener('custom-select:close', () => {
            if (this.customSelect.value.trim().length === 0) {
                this.icon.classList.remove('open')
            }
        })
        this.customSelect.select.addEventListener('change', () => {
            if (this.customSelect.value.trim().length > 0) {
                this.icon.classList.add('hidden')
            } else {
                this.icon.classList.remove('hidden')
            }
        })
    }
    appendIcon() {
        this.customSelectOpener = this.customSelect.opener

        if (this.customSelectOpener instanceof HTMLSpanElement) {
            this.customSelectLabel = this.customSelectOpener?.firstChild?.textContent ?? ''
            this.customSelectOpener.setAttribute('aria-label', this.customSelectLabel)
            this.icon = document.createElement('i')
            this.icon.classList.add('icon')
            this.customSelectOpener.appendChild(this.icon)
        }
    }
}
