export default class Banner {
    constructor(banner) {
        const menuBurger = banner.querySelector('.cta-menu-burger')
        const menuClose = banner.querySelector('.cta-menu-close')
        const navMenuDesktopLinks = banner.querySelectorAll('.nav-primary a')

        banner && (this.banner = banner)
        menuBurger && (this.menuBurger = menuBurger)
        menuClose && (this.menuClose = menuClose)
        navMenuDesktopLinks.length && (this.navMenuDesktopLinks = navMenuDesktopLinks)
    }

    start() {
        this.menuBurger?.addEventListener('click', () => this.banner?.classList.add('nav-mobile-opened'))
        this.menuClose?.addEventListener('click', () => this.banner?.classList.remove('nav-mobile-opened'))

        this.handleNavLinksClick()
    }

    handleNavLinksClick() {
        this.navMenuDesktopLinks.forEach(element => {
            element.addEventListener('click', () => {
                const activeLink = this.banner.querySelector('.current-menu-item')
                element.closest('li')?.classList.add('active')
                if (activeLink) {
                    activeLink.classList.remove('current-menu-item')
                }
            })
        })
    }
}
