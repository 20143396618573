export default class Localisation {
    constructor(localisation) {
        const localisationItems = localisation.querySelectorAll('.localisation-item')
        const localisationCountries = localisation.querySelectorAll('.located-country')

        localisationItems.length > 0 && (this.localisationItems = localisationItems)
        localisationCountries.length > 0 && (this.localisationCountries = localisationCountries)
    }

    start() {
        this.localisationItems.forEach(elt => {
            elt.addEventListener('mouseenter', () => {
                this.localisationCountries.forEach(item => {
                    item.classList.add('disabled')
                    if (item.id && item.id === elt.getAttribute('data-code')) {
                        item.classList.remove('disabled')
                        item.classList.add('selected')
                    }
                })
            })
        })

        this.localisationItems.forEach(elt => {
            elt.addEventListener('mouseleave', () => {
                this.localisationCountries.forEach(item => {
                    item.classList.remove('disabled')
                    item.classList.remove('selected')
                })
            })
        })
    }
}
