import Dropdown from '../constants/dropdown'

export default class FormPage {
    constructor(element) {
        this.element = element
        this.formPageCf7 = this.element.querySelector('.wpcf7')
        this.selectRequest = this.element.querySelector('.wpcf7-form-control.form-select')
        this.customSelectRequest = ''
    }

    start() {
        if (this.formPageCf7 instanceof HTMLDivElement) {
            this.element.classList.add('contact-form-wrapper')
            if (this.element.parentElement && this.element.parentElement.id === 'main') {
                this.element.classList.add('first-block')
            }
            if (this.selectRequest instanceof HTMLSelectElement) {
                this.customSelectRequest = new Dropdown(this.selectRequest)
                this.customSelectRequest.start()
                this.customSelectRequestOpener = this.customSelectRequest.customSelectOpener
                this.customSelectRequestPanel = this.customSelectRequest.customSelect.panel
                this.handleCf7Error()
            }
        }
    }

    handleCf7Error() {
        if (this.selectRequest.classList.contains('form-select')) {
            this.formPageCf7.addEventListener('wpcf7invalid', () => {
                if (
                    this.selectRequest.classList.contains('wpcf7-validates-as-required') &&
                    this.customSelectRequest.customSelect.value === ''
                ) {
                    this.customSelectRequestOpener.classList.add('not-valid')
                    this.customSelectRequestPanel.classList.add('not-valid')
                } else {
                    this.customSelectRequestOpener.classList.remove('not-valid')
                    this.customSelectRequestPanel.classList.remove('not-valid')
                }
            })
        }
    }
}
