export default class KeyNumbers {
    constructor(element) {
        this.element = element
        this.keyNumbers = this.element.querySelectorAll('.fd-key-numbers-item .key-title')
        this.incremented = false
    }

    start() {
        window.addEventListener('load', this.triggerAnimationKeyNumber.bind(this))
        window.addEventListener('scroll', this.triggerAnimationKeyNumber.bind(this))
    }

    triggerAnimationKeyNumber() {
        const elementTop = this.element.getBoundingClientRect().top
        if (elementTop < window.innerHeight && !this.incremented) {
            this.keyNumbers.forEach(key => {
                this.handleAnimationKeyNumber(key)
            })
            this.incremented = true
        }
    }

    handleAnimationKeyNumber(key) {
        const keyText = key.innerText
        let parsedNumberValue = 0
        let prefix = ''
        let numberValue = ''
        let suffix = ''
        let step

        for (let i = 0; i < keyText.length; i++) {
            if (isNaN(parseInt(keyText[i])) && !numberValue.length) {
                prefix += keyText[i]
            } else if (isNaN(parseInt(keyText[i])) && numberValue.length) {
                suffix += keyText[i]
            } else {
                numberValue += keyText[i]
            }
        }

        if (numberValue.length) {
            parsedNumberValue = parseInt(numberValue)
        }

        switch (numberValue.length) {
            case 2:
                step = 1
                break
            case 3:
                step = 10
                break
            case 4:
                step = 400
                break
            default:
                step = 1000
        }

        this.handleIncrementValue(prefix, parsedNumberValue, suffix, key, step)
    }

    handleIncrementValue(prefix, value, suffix, key, step) {
        let start = 0
        setInterval(() => {
            if (start < value) {
                start += step
                key.innerText = prefix + start + suffix
            } else {
                key.innerText = prefix + value + suffix
            }
        }, 40)
    }
}
