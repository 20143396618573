import Dropdown from '../constants/dropdown'

export default class Contact {
    constructor(element) {
        this.element = element
        this.selectRequest = this.element.querySelector('.wpcf7-form-control.form-select')
        this.customSelectRequest = ''
        this.submitButton = this.element.querySelector('.wpcf7-submit')
    }
    start() {
        this.submitButton.closest('p').style.position = 'relative'

        if (this.selectRequest instanceof HTMLSelectElement) {
            this.customSelectRequest = new Dropdown(this.selectRequest)
            this.customSelectRequest.start()
            this.customSelectRequestOpener = this.customSelectRequest.customSelectOpener
            this.customSelectRequestPanel = this.customSelectRequest.customSelect.panel
            this.handleCf7Error()
        }
    }

    handleCf7Error() {
        if (this.selectRequest.classList.contains('form-select')) {
            const formContact = this.element.querySelector('.wpcf7')
            formContact.addEventListener('wpcf7invalid', () => {
                if (
                    this.selectRequest.classList.contains('wpcf7-validates-as-required') &&
                    this.customSelectRequest.customSelect.value === ''
                ) {
                    this.customSelectRequestOpener.classList.add('not-valid')
                    this.customSelectRequestPanel.classList.add('not-valid')
                } else {
                    this.customSelectRequestOpener.classList.remove('not-valid')
                    this.customSelectRequestPanel.classList.remove('not-valid')
                }
            })
        }
    }
}
