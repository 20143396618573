export default class Cf7Checkbox {
    constructor(element) {
        this.element = element
    }

    start() {
        const checkboxLabel = this.element.parentElement
        const checkmarkSpan = document.createElement('span')

        checkmarkSpan.className = 'checkmark'
        checkboxLabel.appendChild(checkmarkSpan)
    }
}
